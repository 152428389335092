import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'
import SubscriptionPlanService from '@/services/subscription-plan.service'
import { Subscriptions } from '@/classes/helpers/Subscriptions'
import PaymentService from '@/services/payment.service'
import { getParameterByName } from '@/util/GetQueryString.js'
import EventBus from '@/util/EventBus'
import browserCache from '@/services/browser-cache'
import getSymbolFromCurrency from 'currency-symbol-map'

export default {
    name: 'PlanSubscriptionUpdateCard',
    components: {
        HeaderTopDashboard,
    },
    props: {
        payment: {
            type: Object,
            required: true,
        },
    },
    data () {
        return {
            currencySymbol: getSymbolFromCurrency(browserCache.getJson('user').currencyCode),
            setup: 0,
            fee: 0,
            userPlanId: 0,
            userSubscriptionId: 0,
            planType: null,
        }
    },
    computed: {
    },
    methods: {
        getMethodCreditCard () {
            const data = {
                userPlanId: this.userPlanId,
                userSubscriptionId: this.userSubscriptionId,
            }
            PaymentService.getMethodCreditCard(data)
                .then(
                    (response) => {
                        this.payment.activeCreditCard = response.activeCreditCard
                        this.payment.activePlan = response.activePlan
                        this.payment.activeSubscription = response.activeSubscription
                        this.payment.currency = response.currency
                        this.payment.fromPlans = response.fromPlans
                        this.payment.paymentsInfo = response.paymentsInfo
                        this.payment.paymentsInfo.creditCard.fixed_commission = 0
                        this.payment.paymentsInfo.creditCard.commission = 0
                        this.payment.show = true
                        this.getPaymentInvoice()
                        this.getCreditCardPaymentMethod()
                    },
                    () => {},
                )
                .finally(() => {})
        },
        getPaymentInvoice () {
            const token = getParameterByName('token')
            const paymentMethod = getParameterByName('paymentMethod')

            if (token && (parseInt(paymentMethod) === this.payment.paymentMethod.creditCard)) {
                this.getPaymentInvoiceCreditCard(token)
            }
        },
        getPaymentInvoiceCreditCard (token) {
            const data = {
                token: token,
            }

            PaymentService.callbackCreditCardPaymentMethod(data)
                .then(
                    (response) => {
                        this.payment.invoice = response.invoice
                        this.payment.paymentMethodSelectedId = this.payment.paymentMethod.creditCard
                        this.payment.complete = true
                        this.payment.showInvoice = true
                        this.getUserBalance()
                    },
                    (error) => {
                        if (error.response.status === 422) {
                            EventBus.$emit('showAlert', 'danger', this.$t('Error al intentar añadir saldo'))
                        }
                        EventBus.$emit('showAlert', 'danger', this.$t(error.response.data.code))
                    },
                )
                .finally(() => {})
        },
        getCreditCardPaymentMethod () {
            this.loadingCreditCard = true
            PaymentService.getCreditCardPaymentMethod()
                .then(
                    (response) => {
                        this.payment.paymentDetail = response
                        this.payment.defaultCreditCardId = response.subscribedCards.length > 0 ? response.subscribedCards[0].id : '0'
                    },
                    (error) => {
                        PaymentService.errorResponse(
                            error.response.status,
                            this.$t('Error al intentar añadir saldo'),
                            this.$t(error.response.data.code),
                        )
                    },
                )
                .finally(() => {
                    this.loadingCreditCard = false
                    this.updateCreditCardSubscription()
                })
        },
        updateNow () {
            this.getMethodCreditCard()
            this.payment.step = 2
        },
        updateCreditCardSubscription () {
            // console.log('payment', this.payment)
            const data = {
                plan: Subscriptions.WHATSAPP,
                subscription: true,
                infobillingId: this.payment.paymentDetail.infoBilling.billingProfiles[0].id,
                subscribed_card: this.userSubscriptionId,
                invoiceTmpId: this.payment.invoiceTmp.id,
                pathOK: '/users/plans',
                pathKO: '/payments/plan-subscription-update-card-needed',
                userPlanId: this.userPlanId,
            }
            SubscriptionPlanService.updateCreditCardSubscription(data).then(
                (response) => {
                    this.payment.setup = 0
                    this.payment.invoiceTmp = 0
                    this.payment.urlCreditCardPay = response.url
                },
                (error) => {
                    console.log('error', error)
                },
            )
        },
    },
    created () {
        this.setup = 0
        this.fee = 0
    },
    mounted () {
        this.userPlanId = this.$route.params.userPlanId
        this.userSubscriptionId = this.$route.params.userSubscriptionId
        this.planType = this.$route.params.planType
        console.log('userPlanId', this.userPlanId, 'userSubscriptionId', this.userSubscriptionId)
    },
}
